import React,{useState,useEffect} from 'react';
// import { SocialIcon } from 'react-social-icons';
import YouTubeSubscribe from "./components/youtubeSubscribe";
import './App.scss';

function App() {
  const [pigged,setPigged] = useState(null);
  const [letVid,setLetVid] = useState(false);
  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
  const handlePig = (e)=>{
    e.preventDefault();
    console.debug('asadasad')
    document.querySelector('#finish').style.top = '-100vh';
    setTimeout(()=>{
      setPigged(true);
    },3000)
  }

  const handleVid = () => {
    setTimeout(()=>{
      setLetVid(true);
      const video = document.querySelector('video');
      video.style.width = 'auto';
      video.style.height = '400px';
      video.style.top = '150px';
      video.volume = 0.5;
    },4000)
  }

  return (
    <>
      {
        !isMobile?
        <div className="App">
          <div id='start'>
            <div className='text'>
              <h1>Welcome child, to smoothbrain.mom</h1>
              <h3>smoothbrain.com is taken by some investment company</h3>
              <h1>I assure you it is not malicious</h1>
              <h1>At first sight this page may appear very simple, but it holds much more</h1>
              <h1>If you wish to reveal the secrets of this site, all you have to do is click the biblically accurate pig</h1>
              <h1>𓆩ɃɆ ꞤꝊȾ ȺӺꞦȺĪĐ𓆪</h1>
            </div>
            <a onClick={handlePig} className='pigLink' >
              <img className='pig' src='./assets/biblically_accurate_pig.png'/>
            </a>
          </div>
          <div id='finish'>
            {pigged &&
            <>
              <h1>That was too easy</h1>
              <video autoPlay controls={letVid} onPlay={handleVid} volume={1} >
                <source src="./assets/vid.mp4" type="video/mp4" />
              </video>
              <div className='text'>
                <h2>Am I going to far? Anyway... Donate to charity or something, Habie got enough of our money</h2>
                <a href='https://donate.nami.org/give/197406/#!/donation/checkout' target='_blank' >
                  <img src='./assets/nami-logo-blue.jpg'/>
                </a>
              </div>
              <div className='sub'>
                <h2>Don't sub</h2>         
                <YouTubeSubscribe
                  channelid={"UCpH6XCRisaIGv9OJydefYWA"}
                  theme={"dark"}
                  layout={"full"}
                  count={"default"}
                />
                <YouTubeSubscribe
                  channelName={"habie147"}
                  theme={"dark"}
                  layout={"full"}
                  count={"default"}
                />
              </div>
            </>

            }
          </div>
        </div>
        :
        <>
        <div className="App">
          <h1>I'm not bothering with mobile design go find a computer</h1>
        </div>
        
        </>
      }
    </>
    
  );
}

export default App;
